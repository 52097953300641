import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Login2EndpointGetHook(
    param_email: string, 
) {
    return GetWithHook(routes.Login, {
        email: param_email, 
    });
}

export function Login2EndpointGetPromise(
    param_email: string, 
) {
    return GetWithPromise(routes.Login, {
        email: param_email, 
    });
}

export function Login2EndpointPostPromise(
    param_email: string, 
) {
    return PostWithPromise(routes.LoginPost, {
        email: param_email, 
    });
}

