export const routes = {
    Language: '/language.json?text={text}',
    Ping: '/ping.json?pingMessage={pingMessage}',
    Exists: '/user/exists.json?email={email}',
    Login: '/user/login.json?email={email}',
    Signup: '/user/signup.json',
    Verify: '/user/verify.json?email={email}&code={code}',
    PingPost: '/ping.json',
    LoginPost: '/user/login.json',
    SignupPost: '/user/signup.json',
    VerifyPost: '/user/verify.json',
};
