import { KeyboardArrowRight } from '@mui/icons-material';
import { Button } from '@mui/material';

interface Props {
    onClick: () => void;
}

function NextPageButton(props: Props) {
    return (
        <Button
            variant='contained'
            color='secondary'
            sx={{
                width: '100%',
            }}
        >
            <KeyboardArrowRight
                onClick={props.onClick}
            />
        </Button>
    );
}

export default NextPageButton;
