import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './Error';
import { ThemeProvider } from '@mui/material';
import { theme } from './generals/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import Website from './generals/Website';
import Welcome from './Welcome';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <>
                <Welcome />
            </>
        ),
        errorElement: <Error />,
    },
]);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                <Website>
                    <div className="App">
                        <RouterProvider router={router} />
                    </div>
                </Website>
            </LocalizationProvider>
        </ThemeProvider>
    </React.StrictMode >
);
