import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            light: '#AEBDF4',
            main: '#3277e5',
            dark: '#1e4bb3',
            contrastText: '#f6f8ec',
        },
        text: {
            primary: '#f6f8ec',
            secondary: '#cfcfcf',
            disabled: '#cccccc',
        },
        secondary: {
            light: '#F9A3B0',
            main: '#EC5272',
            dark: '#AE0843',
        },
        background: {
            default: '#4388f6',
            paper: '#7176DF',
        },
        error: {
            main: '#ba000d',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#f5f7eb',
                        },
                    },
                    '&:hover .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#f5f7eb',
                        },
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                        '& fieldset': {
                            borderColor: '#f5f7eb',
                        },
                    },
                    '& .MuiInputLabel-outlined.Mui-focused': {
                        color: '#f5f7eb',
                    }
                },
            },
        },
    },
    typography: {
        allVariants: {
            color: '#f5f7eb',
        },
    },
});
