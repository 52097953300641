import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function Exists2EndpointGetHook(
    param_email: string, 
) {
    return GetWithHook(routes.Exists, {
        email: param_email, 
    });
}

export function Exists2EndpointGetPromise(
    param_email: string, 
) {
    return GetWithPromise(routes.Exists, {
        email: param_email, 
    });
}

