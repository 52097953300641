import { Box, Container, FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NextPageButton from './generals/buttons/NextPageButton';
import { Exists2EndpointGetHook, Exists2EndpointGetPromise } from './routes/Exists';
import { Language2EndpointGetHook } from './routes/Language';
import { Login2EndpointPostPromise } from './routes/Login';
import { Signup2EndpointPostPromise } from './routes/Signup';

function Welcome() {
    const navigate = useNavigate();

    const [translations, setTranslations] = useState({
        email: '',
        token: '',
    });

    const translationResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    useEffect(() => {
        if (translationResponse.data) {
            setTranslations(translationResponse.data.translations);
        }
    }, [translationResponse.data]);

    const [emailState, setEmailState] = useState('');
    const [tokenState, setTokenState] = useState('');

    const isEmailValid = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isTokenValid = (token: string) => {
        return /^[a-zA-Z0-9]{6}$/.test(token);
    };

    const [verifyState, setVerifyState] = useState(false);

    const showVerify = () => {
        const existsPromise = Exists2EndpointGetPromise(emailState);

        existsPromise.then((existsResponse) => {
            if (existsResponse.data.exists === true) {
                const loginPromise = Login2EndpointPostPromise(emailState);

                loginPromise.then((loginResponse) => {
                    if (loginResponse.status === 200) {
                        setVerifyState(true);
                    }
                });
            } else {
                const signupPromise = Signup2EndpointPostPromise('', emailState, '1');

                signupPromise.then((signupResponse) => {
                    if (signupResponse.status === 200) {
                        setVerifyState(true);
                    }
                });
            }
        });
    };

    const continueToHome = () => {
        navigate('/dashboard');
    };

    return (
        <Container maxWidth='lg'>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    width: 400,
                }}
            >
                {!verifyState &&
                    <FormControl
                        fullWidth
                    >
                        <TextField
                            label={translations.email}
                            value={emailState}
                            onChange={(event) => setEmailState(event.target.value)}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    showVerify();
                                }
                            }}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </FormControl>
                }
                {verifyState &&
                    <FormControl
                        fullWidth
                    >
                        <TextField
                            label={translations.token}
                            value={tokenState}
                            onChange={(event) => setTokenState(event.target.value)}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    continueToHome();
                                }
                            }}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </FormControl>
                }
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    right: '0',
                    bottom: '0',
                    margin: '1rem',
                }}
            >
                <FormControl
                    fullWidth
                    sx={{
                        marginTop: '1rem',
                        transition: 'all 0.3s ease',
                    }}
                >
                    {isEmailValid(emailState) && !verifyState && (
                        <NextPageButton
                            onClick={showVerify}
                        />
                    )}
                    {isTokenValid(tokenState) && verifyState && (
                        <NextPageButton
                            onClick={continueToHome}
                        />
                    )}
                </FormControl>
            </Box>
        </Container>
    );
}

export default Welcome;
