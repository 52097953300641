import { theme } from './theme';

// This is just a function component returning a div with the given children
export default function Website(props: { children: React.ReactNode }) {
    return (
        <div
            style={{
                backgroundColor: theme.palette.background.paper,
                minHeight: '100vh',
                minWidth: '100vw',
            }}
        >
            {props.children}
        </div>
    );
}

